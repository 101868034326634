const htmlStyle = window.document.documentElement.style;
const prefixes = ['webkit', 'ms'];

function detectPropertyName(propertyName: string) {
    let modifiedProperty;
    let i;
    let iMax;
    let testableProperty;

    if (propertyName in htmlStyle) {
        return propertyName;
    }

    modifiedProperty = propertyName.charAt(0).toUpperCase() + propertyName.substr(1);

    for (i = 0, iMax = prefixes.length; i < iMax; i ++ ) {
        testableProperty = prefixes[i] + modifiedProperty;
        if (testableProperty in htmlStyle){
            return testableProperty;
        }
    }

    return propertyName;
}

export const transform = detectPropertyName('transform');
export const transition = detectPropertyName('transition');